<template>
  <v-row>
    <v-col class="py-0 pr-0 d-flex align-center justify-start">
      {{ title }}
      <div v-if="display && isMediumBreakpointOrLarger">
        <v-icon
          v-for="(item, i) in items"
          :key="i"
          class="ml-2 primary--text"
          dense
        >
          {{ ICON_MAP[item[property]] }}
        </v-icon>                        
      </div>
    </v-col>
  </v-row>
</template>

<script>

import { SOCIAL_MEDIA_ICON_MAP } from '../../constants'

export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    display: {
      type: Boolean,
      default: false,
    },
    property: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMediumBreakpointOrLarger () {
      return this.$vuetify.breakpoint.name !== 'sm' && this.$vuetify.breakpoint.name !== 'xs'
    },
  },
  methods: {
    initTemplateConstants () {
      this.ICON_MAP = SOCIAL_MEDIA_ICON_MAP
    }
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>